import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Autocomplete from '@mui/material/Autocomplete';
import CompanyDetails from "./CompanyDetails";
import {useState} from "react";
import {renderReferralData} from "../../utils/Helpers";

/**
 * @param props
 * @returns {JSX.Element}
 * @private
 */
const _renderCompanyDetailsOrAutoComplete = (props): JSX.Element => {
	const [searchTimer, setSearchTimer] = useState(null);

	if (!props) return;

	const {
		company,
		token = false,
	} = props;

	if (token) {
		const {
			setupData = {
				employer: {
					title: "",
					company_number: 0,
				}
			}
		} = props;

		return (
			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<Typography
					align={"center"}
					variant={"h5"}
				>
					{
						company instanceof Array ?
							company
							:
							`${setupData?.employer?.company_number} – ${setupData?.employer?.title}`
					}.
				</Typography>
			</FormControl>
		);
	}

	return (
		<FormControl
			className={"signup-form-control"}
			fullWidth
		>
			<Autocomplete
				id="companies-dropdown"
				options={props.companies ? Object.values(props.companies) : []}
				getOptionLabel={(option) =>
					option && (typeof (option) === 'object' ?
							option.company_number + ' - ' + option.title
							:
							option
					)}
				onChange={(event, newValue) => {
					if (!newValue) return;
					props.setCompany(newValue);
				}}
				clearIcon={false}
				isOptionEqualToValue={(option, employer) => option && (option.title === employer.title)}
				renderInput={(params) =>
					<TextField
						{...params}
						onKeyUp={event => {
							if (searchTimer) clearTimeout(searchTimer);
							if (event.target.value.length > 0) {
								setSearchTimer(
									setTimeout(() =>
											props.search({
												q: event.target.value,
												items_per_page: 0
											}),
										1000
									)
								);
							} else {
								props.setCompany("");
							}
						}}
						label="Search your company name"
						variant="outlined"
						name={"searchCompany"}
						id={"searchCompany"}
					/>
				}
			/>
		</FormControl>
	);
}

/**
 * @param props
 * @returns {JSX.Element}
 * @private
 */
const _renderFormTitle = (props): JSX.Element => {
	if (props) {
		const {
			company = false,
			token = false,
		} = props;
		if (company && token) {
			return (
				<FormControl
					className={"signup-form-control-2 first-title"}
					fullWidth
				>
					<Typography align={"center"}>
						This is your company
					</Typography>
				</FormControl>
			);
		}
	}

	return (
		<FormControl
			className={"signup-form-control-2 first-title"}
			fullWidth
		>
			<Typography align={"center"}>
				Now, find your company
			</Typography>
		</FormControl>
	);
};

/**
 * @param props
 * @returns {JSX.Element}
 * @private
 */
const _renderFormSubTitle = (props): JSX.Element => {
	if (props) {
		const {
			company = false,
			token = false,
		} = props;

		if (company && token) {
			return (
				<FormControl
					className={"signup-form-control-2 second-title"}
					fullWidth
				>
					<Typography align={"center"}>
						More company information below.
					</Typography>
				</FormControl>
			);
		}
	}

	return (
		<FormControl
			className={"signup-form-control-2 second-title"}
			fullWidth
		>
			<Typography align={"center"}>
				type your company name or enter manually
			</Typography>
		</FormControl>
	);
}

/**
 * @param props
 * @returns {JSX.Element|*[]}
 * @private
 */
const _renderAlreadyExistsMessage = (props: any[] = false): JSX.Element | *[] => {
	if (props) {
		const {
			alreadyExistsMessage = ""
		} = props;

		if (alreadyExistsMessage !== "") {
			return (
				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
                    <span className={"error"}>
                        {alreadyExistsMessage ?? ""}
                    </span>
				</FormControl>
			);
		}
	}

	return [];
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FindCompanyDetails = (props = {
	token: "",
	referral: "",
	formValues: {},
	directorList: [{}],
	company: [],
	companies: [],
	setupData: [{}],
	enterManually: false,
	companyCheckAddress: false,
	checkConfirm: false,
	addDirector: false,
	alreadyExistsMessage: "",
	setFormValues: () => {},
	setDirectorList: () => {},
	setCompany: () => {},
	setAddDirector: () => {},
	setCheckConfirm: () => {},
	setCompanyCheckAddress: () => {},
	setEnterManually: () => {},
	search: () => {},
	getOfficers: () => {},
	proceedStep: () => {},
	backStep: () => {}
}): JSX.Element => {
	return (
		<Grid
			id={"signup-container"}
			size={12}
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			spacing={0}
			container
			item
		>
			<Grid
				display={props.enterManually ? {xs: "none"} : {lg: "block"}}
			>
				{renderReferralData(props?.referral ?? {})}
				{_renderFormTitle(props ?? false)}
				{_renderFormSubTitle(props ?? false)}
				{_renderCompanyDetailsOrAutoComplete(props ?? false)}
				{_renderAlreadyExistsMessage(props ?? false)}
				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							!props.token ?
								props.getOfficers({
									company_number: props.company.company_number,
									items_per_page: 0,
								})
								:
								props.getOfficers({
									company_number: props.setupData?.employer?.company_number,
									items_per_page: 0,
								});
							props.proceedStep();
						}}
						disabled={props.company.length === 0 && !props.token && !props.enterManually}
					>
						Continue
					</Button>
				</FormControl>

				<FormControl
					className={"signup-form-control-2 forgot-password"}
					fullWidth
				>
					Enter
					<span
						className={"back-span"}
						onClick={() => props?.backStep()}
					>
					Back
				</span>
				</FormControl>
				{
					!props.token ?
						<>
							<FormControl
								className={"signup-form-control-2 forgot-password"}
								fullWidth
							>
								<small className={"font-size-09"}>
									Didn't find your company?
								</small>
							</FormControl>
							<FormControl
								className={"signup-form-control-2 forgot-password"}
								fullWidth
							>
							<span
								className={"font-size-09"}
								onClick={() => {
									props.setEnterManually(!props.enterManually);
									props.setFormValues({...props.formValues, manual_creation: true});
								}}
							>
								Enter details manually
								</span>
							</FormControl>
						</>
						:
						""
				}
			</Grid>

			{
				props.enterManually ?
					<CompanyDetails
						company_number={props.formValues.company_number}
						company_legal_structure={props.formValues.company_legal_structure}
						company_name={props.formValues.company_name}
						company_legal_structure_options={props.formValues.company_legal_structure_options}
						company_registered_address={props.formValues.company_registered_address}
						company_registered_address_postcode={props.formValues.company_registered_address_postcode}
						company_trading_address={props.formValues.company_trading_address}
						company_trading_postcode={props.formValues.company_trading_postcode}
						formValues={props.formValues}
						directorList={props.directorList}
						addDirector={props.addDirector}
						enterManually={props.enterManually}
						companyCheckAddress={props.companyCheckAddress}
						checkConfirm={props.checkConfirm}
						error={props.error ?? false}
						setFormValues={props.setFormValues}
						setDirectorList={props.setDirectorList}
						setAddDirector={props.setAddDirector}
						setEnterManually={props.setEnterManually}
						setCompanyCheckAddress={props.setCompanyCheckAddress}
						setCheckConfirm={props.setCheckConfirm}
						proceedStep={props.proceedStep}
					/>
					:
					[]
			}

		</Grid>
	);
}
/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
export default FindCompanyDetails;