import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddDirector from "./AddDirector";
import Autocomplete from "@mui/material/Autocomplete";
import {useDispatch} from "react-redux";
import {generateCompanyNumber} from "../../actions/Employer";
import {validateEmail} from "../../utils/Helpers";

/**
 * @param enterManually
 * @param setEnterManually
 * @param setCompanyCheckAddress
 * @param setDirectorList
 * @param formValues
 * @param setFormValues
 * @private
 */
const _closeManually = (
	enterManually,
	setEnterManually,
	setCompanyCheckAddress,
	setDirectorList,
	formValues,
	setFormValues
) => {
	setEnterManually(!enterManually);
	setCompanyCheckAddress(false);
	setDirectorList([]);
	setFormValues({...formValues, manual_creation: false});
}

/**
 * @param formValues
 * @param companyCheckAddress
 * @param setFormValues
 * @param setCompanyCheckAddress
 * @param companyTradingAddress
 * @param companyTradingPostcode
 * @private
 */
const _autoCompleteAddress = (
	formValues,
	companyCheckAddress,
	setFormValues,
	setCompanyCheckAddress,
	companyTradingAddress,
	companyTradingPostcode
) => {
	setCompanyCheckAddress(!companyCheckAddress);
	setFormValues({
		...formValues,
		company_registered_address: companyTradingAddress,
		company_registered_address_postcode: companyTradingPostcode,
	});
};

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CompanyDetails = (props = {
	company_number: "",
	company_legal_structure: "",
	company_name: "",
	company_legal_structure_options: [],
	company_registered_address: "",
	company_registered_address_postcode: "",
	company_trading_address: "",
	company_trading_postcode: "",
	companyCheckAddress: false,
	formValues: {},
	directorList: [{}],
	addDirector: false,
	enterManually: true,
	checkConfirm: false,
	error: "",
	setFormValues: () => {},
	setDirectorList: () => {},
	setAddDirector: () => {},
	setEnterManually: () => {},
	setCompanyCheckAddress: () => {},
	setCheckConfirm: () => {},
	proceedStep: () => {}
}): JSX.Element => {
	let dispatch = useDispatch();
	return (
		<Grid
			id={"signup-container-detail"}
			size={12}
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			spacing={0}
			container
			item
		>
			<Grid
				size={12}
				alignItems={"flex-end"}
				alignContent={"flex-end"}
				direction={"column"}
				item
				container
			>
				<Grid
					size={12}
					item
				>
					<IconButton
						onClick={() =>
							_closeManually(
								props.enterManually,
								props.setEnterManually,
								props.setCompanyCheckAddress,
								props.setDirectorList,
								props.formValues,
								props.setFormValues
							)
						}
						className={'header-close-button'}
						disableTouchRipple
						disableFocusRipple
						disableRipple
					>
						<CloseIcon/>
					</IconButton>

				</Grid>
			</Grid>
			<form
				noValidate
				autoComplete="off"
			>
				<FormControl
					className={"signup-form-control-2 first-title"}
					fullWidth
				>
					<Typography align={"center"}>
						Company details
					</Typography>
				</FormControl>
				<FormControl
					className={"signup-form-control-2 second-title"}
					fullWidth
				>
					<Typography align={"center"}>
						enter your company details manually
					</Typography>
				</FormControl>
				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<TextField
						type={"text"}
						onChange={(event) => {
							props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
						}}
						label="Company / Business name*"
						variant="outlined"
						name={"company_name"}
						id={"company_name"}
						value={props.company_name ?? ""}
					/>
				</FormControl>

				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<Autocomplete
						id="legal-structure-dropdown"
						options={props.company_legal_structure_options ?? []}
						getOptionLabel={(option) => option.title}
						onChange={(event, value) => {
							if (value?.title === "Limited Partnership" ||
								value?.title === "Unincorporated Association" ||
								value?.title === "Ordinary Business Partnership" ||
								value?.title === "Sole Trader" ||
								value?.title === "Charity"
							) {
								props.setFormValues({
									...props.formValues,
									company_number: ""
								});
								dispatch(generateCompanyNumber(false));
							} else {
								dispatch(generateCompanyNumber(true));
								props.setFormValues({
									...props.formValues,
									company_number: ""
								});
							}
							props.setFormValues({
								...props.formValues,
								company_legal_structure: value?.title
							});
						}}

						renderInput={(params) =>
							<TextField
								{...params}
								onChange={(event) =>
									props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
								}
								onClick={(event) =>
									props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
								}
								label="Legal structure*"
								variant="outlined"
								name={"company_legal_structure"}
								id={"company_legal_structure"}
								value={props.company_legal_structure ?? ""}
							/>
						}
					/>
				</FormControl>

				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<TextField
						type={"text"}
						onChange={(event) => {
							props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
						}}
						label="Company / Business number*"
						variant="outlined"
						name={"company_number"}
						id={"company_number"}
						value={props.company_number ?? ""}
						disabled={
							props.company_legal_structure === "Limited Partnership" ||
							props.company_legal_structure === "Unincorporated Association" ||
							props.company_legal_structure === "Ordinary Business Partnership" ||
							props.company_legal_structure === "Sole Trader" ||
							props.company_legal_structure === "Charity"
						}
					/>
				</FormControl>

				<Divider/>

				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<TextField
						type={"text"}
						onChange={(event) =>
							props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
						}
						label="Trading address postcode"
						variant="outlined"
						name={"company_trading_postcode"}
						id={"company_trading_postcode"}
						value={props.company_trading_postcode ?? ""}
					/>
				</FormControl>

				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<TextField
						type={"text"}
						onChange={(event) =>
							props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
						}
						label="Trading address*"
						variant="outlined"
						name={"company_trading_address"}
						id={"company_trading_address"}
						value={props.company_trading_address ?? ""}
					/>
				</FormControl>

				<FormControlLabel
					className={"signup-form-control-checkbox"}
					control={
						<Checkbox
							name={"company_check_address"}
							id={"company_check_address"}
							onChange={() => {
								!props.companyCheckAddress ?
									_autoCompleteAddress(
										props.formValues,
										props.companyCheckAddress,
										props.setFormValues,
										props.setCompanyCheckAddress,
										props.company_trading_address,
										props.company_trading_postcode
									)
									:
									_autoCompleteAddress(
										props.formValues,
										props.companyCheckAddress,
										props.setFormValues,
										props.setCompanyCheckAddress,
										"",
										""
									)
							}}
							value={props.companyCheckAddress}
						/>
					}
					label="My registered address is the same of my trading address"
				/>

				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<TextField
						type={"text"}
						onChange={(event) =>
							props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
						}
						label="Registered address postcode"
						variant="outlined"
						name={"company_registered_address_postcode"}
						id={"company_registered_address_postcode"}
						InputProps={{
							readOnly: props.companyCheckAddress,
						}}
						value={props.company_registered_address_postcode ?? ""}
					/>
				</FormControl>

				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<TextField
						type={"text"}
						onChange={(event) =>
							props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
						}
						label="Registered address"
						variant="outlined"
						name={"company_registered_address"}
						id={"company_registered_address"}
						InputProps={{
							readOnly: props.companyCheckAddress,
						}}
						value={props.company_registered_address ?? ""}
					/>
				</FormControl>

				<Divider/>

				<FormControl
					className={"signup-form-control-2 first-title"}
					fullWidth
				>
					<Typography align={"left"}>
						Director / authorised signatory
					</Typography>
				</FormControl>
				<FormControl
					className={"signup-form-control-2 director-text"}
					fullWidth
				>
					<Typography align={"left"}>
						Which Director or Authorised Signatory is signing up on behalf of the company?
					</Typography>
				</FormControl>

				<AddDirector
					directorList={props.directorList}
					setDirectorList={props.setDirectorList}
				/>

				<Divider/>

				<FormControl
					className={"signup-form-control-2 director-text"}
					fullWidth
				>
					<Typography align={"left"}>
						You can only apply to set up a pension scheme for a company that
						you lawfully represent or work for.
					</Typography>
				</FormControl>
				<FormControlLabel
					className={"signup-form-control-checkbox"}
					control={
						<Checkbox
							name={"check_confirm"}
							id={"check_confirm"}
							onChange={() =>
								props.setCheckConfirm(!props.checkConfirm)
							}
							value={props.checkConfirm}
						/>
					}
					label="I confirm that I'm authorised to act on behalf of the company*"
				/>

				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<Button
						variant="contained"
						color="primary"
						onClick={() => props.proceedStep()}
						disabled={(
								!props.company_name ||
								!props.company_legal_structure ||
								!props.company_number ||
								!props.company_trading_address ||
								!props.company_trading_postcode ||
								!props.company_registered_address ||
								!props.company_registered_address_postcode ||
								!props.checkConfirm ||
								(!props?.directorList?.name ||
									!validateEmail(props?.directorList?.email))) ||
							props.error
						}
					>
						Continue
					</Button>
				</FormControl>
			</form>
		</Grid>
	);
}

/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
export default CompanyDetails;
